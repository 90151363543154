<template>
  <BannerComponent />
  <section class="about_us_section mt-5">
    <div
      class="container about_container_Home"
      style="display: grid; grid-template-columns: 60% 40%; gap: 10px"
    >
      <div data-aos="fade-right" data-aos-duration="1000">
      <h2>About Us</h2><img src="../assets/img/gif/About_us.gif" alt="" style="height: 40px;
    width: 40px;
    position: absolute;   
    left: 255px;
    margin-top: -45px;
}">
        <hr />
        <p>
          Let’s start to focusing on 22nd Century and ready to start work on ERP with Big
          Data. Some Non-IT professionals are thinking that, “IT is a wastage of money”,
          DON’T, without IT you can’t grow and be ready to listen your IT requirements and
          if you want to grow - don’t ignore. Remember a Sales Personal can only sale
          his/her product, but Support Personal can handle sales because he/she knows how
          to achieve their business goal with correct requirements.
        </p>

        <p>
          As LifeLayer is running by Medical Doctor and IT Engineer, a Doctor knows how to
          treat patient and what patient is expecting from Doctor and IT engineer knows
          how to get resolution for your IT problems.
        </p>

        <div class="about_us_Boxes">
          <RouterLink to="/OurTeam"
            ><div class="about_usabout">
              <img src="../assets/img/gif/society.gif" />
              <h3>Our Team</h3>
            </div></RouterLink
          >
          <RouterLink to="Our_Goal"
            ><div class="about_usabout">
              <img src="../assets/img/gif/goals.gif" />
              <h3>Our Goal</h3>
            </div></RouterLink
          >
          <RouterLink to="/Business_Philosophy"
            ><div class="about_usabout">
              <img src="../assets/img/gif/business-plan.gif" />
              <h3>Business Philosophy</h3>
            </div></RouterLink
          >
          <RouterLink to="/Technology_life"
            ><div class="about_usabout">
              <img src="../assets/img/gif/coding.gif" />
              <h3>Technology</h3>
            </div></RouterLink
          >
        </div>
      </div>

      <div
        style="height: 500px; width: 100%"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <img
          src="../assets/img/gif/about.gif"
          class="about_us_gif"
          style="height: 100%; width: 100%; object-fit: cover"
        />
      </div>
    </div>
  </section>

  <section style="padding: 20px 0px; margin-bottom: 40px">
      <div class="container"  data-aos="fade-up" data-aos-duration="2000">
        <h2>Our Service</h2><img src="../assets/img/gif/helpdesk.gif" alt="" style="height: 40px;
    width: 40px;
    position: absolute;
    left: 280px;
    margin-top: -45px;
}">
        <hr /><p style="width: 80%;">
          "Our services are designed to bridge the gap between healthcare and technology, delivering innovative solutions for a seamless experience. We specialize in app development, CMS web solutions, and digital marketing tailored to the healthcare industry. Our expertise ensures streamlined operations, improved patient care, and enhanced engagement. Partner with us to drive efficiency and achieve your business goals."</p>
    <div class="row">
        <div class="col-md-3 col-sm-6">
            <div class="serviceBox mb-3">
                <div class="service-icon">
                    <span><img src="../assets/img/erp.png" alt="" width="50px" height="50px"></span>
                </div>
                <h3 class="title">HealthCare ERP</h3>
                <p class="description">It's not surprising that 44% of current ERP prospects are using multiple disparate systems that causes lots of manual and duplicate entry.</p>
                <router-link to="/ERP_Consulting" class="read-more">Read More</router-link>
            </div>
        </div>
        
        <div class="col-md-3 col-sm-6" >
            <div class="serviceBox cyan">
                <div class="service-icon">
                    <span><img src="../assets/img/chat.png" alt="" height="50px" width="50px"></span>
                </div>
                <h3 class="title">Chating System</h3>
                <p class="description">A Chat System for a website enhances user engagement by enabling real-time communication. It facilitates instant support & personalized assistance.</p>
                <router-link to="/Chatting_System" class="read-more">Read More</router-link>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="serviceBox cyan">
                <div class="service-icon">
                    <span><img src="../assets/img/technology.png" alt="" height="50px" width="50px"></span>
                </div>
                <h3 class="title">Content Management system</h3>
                <p class="description">
                  A Content Management System (CMS) is a software platform that simplifies creating, managing, and modifying. </p>
                <a href="#" class="read-more">Read More</a>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="serviceBox cyan">
                <div class="service-icon">
                    <span><img src="../assets/img/app-development.png" alt="" height="50px" width="50px"></span>
                </div>
                <h3 class="title">APP Development</h3>
                <p class="description">We specialize in creating high-performance, user-friendly mobile and web applications tailored to meet your business needs.</p>
               <router-link to="/Development_process" class="read-more">Read More</router-link>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="serviceBox cyan">
                <div class="service-icon">
                    <span><img src="../assets/img/search.png" alt="" height="50px" width="50px"></span>
                </div>
                <h3 class="title">Job Board</h3>
                <p class="description">
                  A job board is an online platform that connects employers with job seekers, enabling businesses to post job openings. </p>
               <router-link to="/JobBoard" class="read-more">Read More</router-link>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="serviceBox cyan">
                <div class="service-icon">
                    <span><img src="../assets/img/it-support.png" alt="" height="50px" width="50px"></span>
                </div>
                <h3 class="title">IT Support</h3>
                <p class="description">
                  IT Support ensures the smooth functioning of technology systems by providing technical assistance, troubleshooting, and maintenance. </p>
               <router-link to="/ItSupport" class="read-more">Read More</router-link>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="serviceBox cyan">
                <div class="service-icon">
                    <span><img src="../assets/img/technical-support.png" alt="" height="50px" width="50px"></span>
                </div>
                <h3 class="title">Education ERP</h3>
                <p class="description">An Education ERP (Enterprise Resource Planning) system is an integrated software solution designed to streamline and manage administrative.</p>
               <router-link to="/EducationERP" class="read-more">Read More</router-link>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="serviceBox cyan">
                <div class="service-icon">
                    <span><img src="../assets/img/content.png" alt="" height="50px" width="50px"></span>
                </div>
                <h3 class="title">Digital Marketing</h3>
                <p class="description">Digital marketing uses online platforms and technologies to promote brands, reach target audiences, and drive business growth.</p>
               <router-link to="/Digital_Marketing" class="read-more">Read More</router-link>
            </div>
        </div>
    </div>
  </div>

  </section>
  <!-- //About us -->
  <div style="height: 200px !important;" class="banner_content mt-2">
    <!-- Overlay Layer -->
    <div
      style="
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(68, 96, 179, 0.3));
        z-index: 99;
      "
    ></div>

    <!-- Content Layer -->
    <div
      style="
        position: relative;
        z-index: 999;
        display: grid;
        place-items:center;
        place-content: center
      "
    >
      <div>
        
        <p
          style="
            text-align: justify;
            color: white;
            font-size: 18px;
            text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
          "
        >
        Get in touch with us today to explore how our comprehensive services can address your unique needs. <br>Our experienced team is fully prepared to assist you with tailored solutions and provide prompt, <br>reliable support to help you achieve your goals.<br />
        <router-link to="/Contact_Us">
          <button
            style="
                 padding: 10px 30px;
    border-radius: 30px;
    border: none;
    outline: none;
    background: #4460b3;
    margin: 0px 6px; 
    color:white;
    margin-top:10px;"

          >
           Contact Us →
          </button>
        </router-link>
        </p> 
      </div>

      <div></div>
    </div>
  </div>

  <!-- why us -->
  <div class="container-fluid why-LifeLayer mt-5">
    <div class="container" >
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <h2 id="about_us">
            Why Choose LifeLayer?
          </h2>
          <p style="width: 80%;">"At Lifelayer Health Solutions, we combine cutting-edge technology with deep healthcare expertise to deliver innovative, reliable, and scalable solutions. Our dedicated team ensures personalized service tailored to your unique needs. We focus on enhancing efficiency, improving patient care, and driving digital transformation in the healthcare sector."</p><hr>
        </div>
      </div>
    </div>
    <div class="container why-LifeLayer-sec" style="padding-bottom: 40px">
      <div class="row service-cont">
        <div class="col-lg-4 col-md-4 col-sm-4" id=" responsive_heading">
          <br />
          <div data-aos="fade-right" data-aos-duration="1000">
            <h1 style="color: rgb(3, 52, 64); font-size: 28px">Responsive Design</h1>
            <p style="color: black">
              The design of our product allows to use it on any device - it will always
              look stunning.
            </p>
          </div>
          &nbsp;
          <div data-aos="fade-right" data-aos-duration="2000">
            <h1 style="color: rgb(3, 52, 64); font-size: 28px">Cross-Platform</h1>
            <p style="color: black">
              LifeLayer provides perfect and flawless compatibility between various
              platforms.
            </p>
          </div>
          &nbsp;
          <div data-aos="fade-right" data-aos-duration="3000">
            <h1 style="color: rgb(3, 52, 64); font-size: 28px">Client Satisfaction</h1>
            <p style="color: black">
              LifeLayer was built on the foundation of serving clients, and we have
              effectively supported our clients for more than 13+ years
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="col-lg-12 col-md-12">
            <div data-aos="fade-up" data-aos-duration="1000">
              <img
                class="img-responsive"
                style="width: 100%"
                src="https://img.freepik.com/free-vector/hand-drawn-flat-design-rpa-illustration_23-2149277643.jpg?t=st=1730637071~exp=1730640671~hmac=a96c996849c6330d9f55c978b904250e7fb75cc8602748d91b9a1fd75edd9029&w=740"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <br />
          <div data-aos="fade-left" data-aos-duration="1000">
          <!-- <img style="height:55px; width:47px; position:relative;margin-top:-5px; right:35%;" src="../assets/img/gif/presentation.gif" alt=""> -->
            <h1 style="color: rgb(3, 52, 64); font-size: 28px">Technical Support</h1>
            <p style="color: black">
              LifeLayer supports end users with a professional technical support solution
              at a fraction of the cost.
            </p>
          </div>
          &nbsp;
          <div data-aos="fade-left" data-aos-duration="2000">
          <!-- <img style="height:55px; width:47px; position:relative;margin-top:-5px; right:35%;" src="../assets/img/gif/training.gif" alt=""> -->
            <h1 style="color: rgb(3, 52, 64); font-size: 28px">Training</h1>
            <p style="color: black">
              LifeLayer provides expanded capacity for its clients&#39; training
              operations.
            </p>
          </div>
          &nbsp;
          <div data-aos="fade-left" data-aos-duration="3000">
          <!-- <img style="height:55px; width:47px; position:relative;margin-top:-5px; right:35%;" src="../assets/img/gif/nice.gif" alt=""> -->
            <h1 style="color: rgb(3, 52, 64); font-size: 28px">More Advantages</h1>
            <p style="color: black">
              Need something more? Then our Premium Subscription is what you are looking
              for!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FAQ Section add-- -->
  <!-- <div class="accordion" id="accordionExample" style="width: 100%; margin: 20px auto">
    <div class="col-lg-12 col-md-12">
          <h2 id="about_us">
            Frequently Asked Questions
          </h2>
        </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          Q1. What does Lifelayer Health Solutions Pvt. Ltd. do?
        </button>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          A: Lifelayer Health Solutions Pvt. Ltd. specializes in delivering innovative
          healthcare and IT solutions. We create products and services that improve
          patient outcomes, streamline healthcare processes, and enhance operational
          efficiency for healthcare providers.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwo">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="false"
          aria-controls="collapseTwo"
        >
          Q2. Who are your clients?
        </button>
      </h2>
      <div
        id="collapseTwo"
        class="accordion-collapse collapse"
        aria-labelledby="headingTwo"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          A: Our clients include hospitals, clinics, diagnostic centers, pharmaceutical
          companies, and healthcare technology providers. We also cater to businesses
          seeking IT solutions in the healthcare domain.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingThree">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
          aria-expanded="false"
          aria-controls="collapseThree"
        >
          Q3. How can I get started with your services?
        </button>
      </h2>
      <div
        id="collapseThree"
        class="accordion-collapse collapse"
        aria-labelledby="headingThree"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          A: You can reach out to us via our contact form on the website, email, or call.
          Our team will get in touch to understand your requirements and provide a
          tailored solution.
        </div>
      </div>
    </div>
  </div> -->

  <!-- -----Reviews section--- -->

  <section style="color: #000; background-color: #f3f2f2">
    <div class="container py-5">
      <div class="row d-flex justify-content-center">
        <!-- <div class="col-md-10 col-xl-8 text-center">
          <h3 class="fw-bold mb-4"> Our Testimonials</h3>
          <p class="mb-4 pb-2 mb-md-5 pb-md-0">
          </p>
        </div> -->
        <div class="col-lg-12 col-md-12">
          <h2 id="about_us">
            Our Testimonials
          </h2>
          <p style="width: 80%;">"Partnering with Lifelayer Health Solutions has been a game-changer for our organization. Their innovative approach to healthcare IT has helped streamline our processes and enhance patient care. Their team’s dedication and expertise make them a trusted partner in driving digital transformation."</p><hr>
        </div>
      </div>

      <div class="row text-center">
        <div class="col-md-4 mb-4 mb-md-0">
          <div class="card">
            <div class="card-body py-4 mt-2">
              <div class="d-flex justify-content-center mb-4">
                <img
                  src="../assets/img/gif/speech-bubble.gif"
                  class="rounded-circle shadow-1-strong"
                  width="100"
                  height="100"
                />
              </div>
              <h5 class="font-weight-bold">Ava</h5>
              <h6 class="font-weight-bold my-3">CEO, HealthPlus Medical Group</h6>
              <ul class="list-unstyled d-flex justify-content-center">
                <li>
                  <i class="fas fa-star fa-sm text-info"></i>
                </li>
                <li>
                  <i class="fas fa-star fa-sm text-info"></i>
                </li>
                <li>
                  <i class="fas fa-star fa-sm text-info"></i>
                </li>
                <li>
                  <i class="fas fa-star fa-sm text-info"></i>
                </li>
                <li>
                  <i class="fas fa-star-half-alt fa-sm text-info"></i>
                </li>
              </ul>
              <p class="mb-2">
                <i class="fas fa-quote-left pe-2"></i>"Lifelayer Health Solutions transformed our operations with their innovative technology and skilled team.".
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-4 mb-md-0">
          <div class="card">
            <div class="card-body py-4 mt-2">
              <div class="d-flex justify-content-center mb-4">
                <img
                  src="../assets/img/gif/speech-bubble.gif"
                  class="rounded-circle shadow-1-strong"
                  width="100"
                  height="100"
                />
              </div>
              <h5 class="font-weight-bold">Sophia</h5>
              <h6 class="font-weight-bold my-3">IT Director, MedTech Innovations</h6>
              <ul class="list-unstyled d-flex justify-content-center">
                <li>
                  <i class="fas fa-star fa-sm text-info"></i>
                </li>
                <li>
                  <i class="fas fa-star fa-sm text-info"></i>
                </li>
                <li>
                  <i class="fas fa-star fa-sm text-info"></i>
                </li>
                <li>
                  <i class="fas fa-star fa-sm text-info"></i>
                </li>
                <li>
                  <i class="fas fa-star fa-sm text-info"></i>
                </li>
              </ul>
              <p class="mb-2">
                <i class="fas fa-quote-left pe-2"></i>"A reliable partner in our digital transformation, providing seamless and efficient IT solutions".
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-0">
          <div class="card">
            <div class="card-body py-4 mt-2">
              <div class="d-flex justify-content-center mb-4">
                <img
                  src="../assets/img/gif/speech-bubble.gif"
                  class="rounded-circle shadow-1-strong"
                  width="100"
                  height="100"
                />
              </div>
              <h5 class="font-weight-bold">Isabella
              </h5>
              <h6 class="font-weight-bold my-3">Operations Manager, HealthGuard Services
              </h6>
              <ul class="list-unstyled d-flex justify-content-center">
                <li>
                  <i class="fas fa-star fa-sm text-info"></i>
                </li>
                <li>
                  <i class="fas fa-star fa-sm text-info"></i>
                </li>
                <li>
                  <i class="fas fa-star fa-sm text-info"></i>
                </li>
                <li>
                  <i class="fas fa-star fa-sm text-info"></i>
                </li>
                <li>
                  <i class="far fa-star fa-sm text-info"></i>
                </li>
              </ul>
              <p class="mb-2">
                <i class="fas fa-quote-left pe-2"></i>"Their expertise and dedication consistently exceed our expectations, making them invaluable to our team."
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- services -->
  <!-- <div style="background-color: #dadada; margin: 50px 0px">
    <div class="container our-service-sec">
      <div class="row" style="margin: 15px 0px">
        <div class="col-lg-12 col-md-12">
          <h1 class="text-center" style="font-size: 55px">Our Services</h1>
        </div>
      </div>
    </div>

    <div style="background-color: #dadada" class="services-card-section">
      <div class="card-container">
        <div class="card">
          <img
            src="https://tridentinfo.com/wp-content/uploads/2024/05/Why-Microsoft-Dynamics-365-Business-Central-is-the-Best-ERP-Solution-for-2024.webp"
            alt=""
          />
          <p>Enterprise resource planning (ERP)</p>
        </div>
        <div class="card">
          <img
            src="https://img.freepik.com/free-vector/app-development-banner_33099-1720.jpg?t=st=1730634623~exp=1730638223~hmac=0af8cd48ab9e63633e8b8659e916fd85b80f7fac925d5b3a8d88bde4c7d1272b&w=996"
            alt=""
          />
          <p>app development</p>
        </div>
        <div class="card">
          <img
            src="https://img.freepik.com/free-vector/messaging-application-abstract-concept-illustration-texting-desktop-application-mobile-phone-chat-app-messaging-mobile-soft-social-media-messenger-video-call-sms_335657-930.jpg?t=st=1730638397~exp=1730641997~hmac=0a72df13cea19e542ddce614d6fc1cdb8b7d03d0684be0a0e17437dc4f08791e&w=740"
            alt=""
          />
          <p>chatting system</p>
        </div>
        <div class="card">
          <img
            src="https://img.freepik.com/free-photo/cybersecurity-concept-illustration_23-2151883580.jpg?t=st=1730638599~exp=1730642199~hmac=19520ddb0ce1a7620bf410997b46e43c4214251a012df46ceb16a4be9b52f6ba&w=360"
            alt=""
          />
          <p>face recognition</p>
        </div>
        <div class="card">
          <img
            src="https://img.freepik.com/free-vector/flat-design-cms-concept-illustrated_23-2148799071.jpg?t=st=1730638859~exp=1730642459~hmac=6792e9d17cd57da7ed22b2e71f910cd1d30d2d5f404477dda29f44cdad3b64b6&w=996"
            alt=""
          />
          <p>CMS/Web Development</p>
        </div>
        <div class="card">
          <img
            src="https://img.freepik.com/free-vector/medics-working-charts_1262-19836.jpg?t=st=1730638952~exp=1730642552~hmac=617201ea7981fb2b9e2a2aada1b74d1e1202f3ecc7d67a416bf50ce591a6ac7d&w=1060"
            alt=""
          />
          <p>healthcare ERP</p>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="contact_page" style="background-color: #dadada; margin-top: 40px">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="wrapper">
            <div class="row no-gutters mb-5">
              <div class="col-md-7">
                <div class="contact-wrap w-100 p-md-5 p-4">
                  <h3 class="mb-4">Contact Us</h3>

                  <form
                    @submit.prevent="submitForm"
                    id="contactForm"
                    name="contactForm"
                    class="contactForm"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="label" for="name">Full Name</label>
                          <input
                            type="text"
                            style="padding-top: 13px"
                            class="form-control"
                            v-model="formData.name"
                            placeholder="Name"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="label" for="email">Email Address</label>
                          <input
                            type="email"
                            class="form-control"
                            v-model="formData.email"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="label" for="subject">Subject</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.subject"
                            placeholder="Subject"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group" style="margin: 10px 0px">
                          <label class="label" for="message">Message</label>
                          <textarea
                            class="form-control"
                            v-model="formData.message"
                            cols="30"
                            rows="4"
                            placeholder="Message"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <input
                            type="submit"
                            value="Send Message"
                            class="btn btn-primary"
                          />
                          <div class="submitting"></div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-md-5 d-flex align-items-stretch">
                <div id="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d28061.278674602665!2d77.0173709989104!3d28.459668949636043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1731140720465!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="dbox w-100 text-center">
                  <div class="icon d-flex align-items-center justify-content-center">
                    <span class="fa fa-map-marker"></span>
                  </div>
                  <div class="text">
                    <p style="color: black">
                      <span>Address:</span> Sector-43 Gurugav Haryana
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="dbox w-100 text-center">
                  <div class="icon d-flex align-items-center justify-content-center">
                    <span class="fa fa-phone"></span>
                  </div>
                  <div class="text">
                    <p style="color: black">
                      <span>Phone:</span>
                      <a
                        style="color: rgb(34 15 16); text-decoration: none"
                        href="tel://1234567920"
                        >+ 1235 2355 98</a
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="dbox w-100 text-center">
                  <div class="icon d-flex align-items-center justify-content-center">
                    <span class="fa fa-paper-plane"></span>
                  </div>
                  <div class="text">
                    <p style="color: black">
                      <span>Email:</span>
                      <a
                        style="color: rgb(34 15 16); text-decoration: none"
                        href="mailto:info@yoursite.com"
                        >info@LifeLayer.org</a
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="dbox w-100 text-center">
                  <div class="icon d-flex align-items-center justify-content-center">
                    <span class="fa fa-globe"></span>
                  </div>
                  <div class="text">
                    <p style="color: black; text-decoration: none">
                      <span>Website</span>
                      <a style="color: rgb(34 15 16); text-decoration: none" href="#"
                        >yoursite.com</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->


<contact_Home />
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import contact_Home from '@/components/contact_Home.vue'
import axios from "axios";
import BannerComponent from "@/components/BannerComponent.vue";

export default {
  components: {
    BannerComponent,
    contact_Home
  },

  data() {
    return {
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      cmsServices: [
        {
          id: 1,
          name: "Custom CMS Development",
          description: "Tailored solutions for unique business needs.",
          icon: "bi bi-gear-fill",
        },
        {
          id: 2,
          name: "CMS Migration",
          description: "Seamless migration to modern platforms.",
          icon: "bi bi-arrow-repeat",
        },
        {
          id: 3,
          name: "Plugin Development",
          description: "Extend functionality with custom plugins.",
          icon: "bi bi-plug-fill",
        },
        {
          id: 4,
          name: "CMS Maintenance",
          description: "Ensure smooth performance with regular updates.",
          icon: "bi bi-tools",
        },
      ],
      appServices: [
        {
          id: 1,
          name: "Native App Development",
          description: "High-performance apps for iOS and Android.",
          icon: "bi bi-phone",
        },
        {
          id: 2,
          name: "Hybrid App Development",
          description: "Cross-platform apps for broader reach.",
          icon: "bi bi-globe",
        },
        {
          id: 3,
          name: "Web App Development",
          description: "Responsive and scalable web applications.",
          icon: "bi bi-laptop",
        },
        {
          id: 4,
          name: "App Maintenance",
          description: "Keep your app updated with new features.",
          icon: "bi bi-tools",
        },
      ],
      formMessage: {
        success: "",
        warning: "",
      },
      activetab: "erp",
    };
  },
  methods: {
    async submitForm() {
      try {
        await axios.post("http://localhost:5000/api/contact", this.formData);
        this.formMessage.success = "Your message was sent, thank you!";
        toast.success("Your query have been sent. Our team will reach you soon..!!", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        this.formMessage.warning = "";
        this.clearForm();
      } catch (error) {
        toast.error("Failed To Send Your Message. Please try again later", {
          position: "bottom-right",
          width: "400px",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        this.formMessage.warning = "Failed to send your message. Please try again.";
        this.formMessage.success = "";
      }
    },
    clearForm() {
      this.formData.name = "";
      this.formData.email = "";
      this.formData.subject = "";
      this.formData.message = "";
    },

    setActivetab(tab) {
      this.activetab = tab;
    },
  },
};
</script>

<style scoped>
@import "@/css/animate.css";
@import "@/css/lightbox.min.css";
@import "@/css/style.css";
@import "@/css/style2.css";

.services-card-section .card-container {
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
}

.services-card-section .card-container .card {
  height: 300px;
  width: 350px;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.5s;
  border-radius: 10px;
  overflow: hidden;
  &:hover {
    scale: 1.1;
  }
}

.services-card-section .card-container .card p {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
  margin: 0;
  text-align: center;
  text-transform: capitalize;
}
.services-card-section .card-container .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.services-card-section {
  overflow: hidden; /* Hides overflow for a clean animation */
}

.card-container {
  display: flex;
  animation: scroll 10s linear infinite;
}
.banner_content {
  background: url(../assets/img/Contact_Us.jpg);
  position: relative;
  width: 100%;
  padding: 17px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: grid;
  background-attachment: fixed !important;
  place-items: center;
  place-content: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-40%);
  }
}

/* --review css--- */

@import url("https://fonts.googleapis.com/css2?family=Poppins:weight@100;200;300;400;500;600;700;800&display=swap");

@media only screen and (max-width: 768px) {
  .container {
    margin-top: 66px;
  }
}
.height {
  height: 100vh;
}
.card {
  border: none;
  cursor: pointer;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}
.card:hover {
  background-color: #eee;
}

.ratings i {
  color: orange;
}

.testimonial-list {
  list-style: none;
}

.testimonial-list li {
  margin-bottom: 20px;
}

.testimonials-margin {
  margin-top: -19px;
}
article {
  --img-scale: 1.001;
  --title-color: black;
  --link-icon-translate: -20px;
  --link-icon-opacity: 0;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #fff;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

article a::after {
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  cursor: pointer;
  content: "";
}

/* basic article elements styling */
article h2 {
  margin: 0 0 18px 0;
  font-size: 1.9rem;
  letter-spacing: 0.06em;
  color: var(--title-color);
  transition: color 0.3s ease-out;
}

figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

article img {
  max-width: 100%;
  transform-origin: center;
  transform: scale(var(--img-scale));
  transition: transform 0.4s ease-in-out;
}

.article-body {
  padding: 24px;
}

article a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #28666e;
}

article a:focus {
  outline: 1px dotted #28666e;
}

article a .icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  transform: translateX(var(--link-icon-translate));
  opacity: var(--link-icon-opacity);
  transition: all 0.3s;
}

/* using the has() relational pseudo selector to update our custom properties */
article:has(:hover, :focus) {
  --img-scale: 1.1;
  --title-color: #28666e;
  --link-icon-translate: 0;
  --link-icon-opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

/************************ 
Generic layout (demo looks)
**************************/

.boxes_section {
  display: grid;
  place-content: center;
  gap: 20px;
}

.articles {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 960px) {
  article {
    container: card/inline-size;
  }
  .article-body p {
    display: none;
  }
}

@container card (min-width: 380px) {
  .article-wrapper {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 16px;
  }
  .article-body {
    padding-left: 0;
  }
  figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  figure img {
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.about_us_section {
  background: rgb(266, 266, 266);
}

.about_us_gif {
  /* filter: drop-shadow(5px 5px 4px #4444dd); */
  border-radius: 4%;
}

.about_us_Boxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 75%;
  gap: 15px;
}

.about_usabout {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  display: grid;
  place-items: center;
  place-content: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.about_usabout h3 {
  font-size: 15px;
}
.about_usabout img {
  width: 40px;
}

.about_usabout:hover {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(68, 96, 179, 0.6));
  color: #fff;
  transition: 0.5s;
}
.about_usabout:hover img {
  mix-blend-mode: color-burn;
}

.service_grid {
  display: grid;
  grid-template-columns: 25% 65%;
  gap: 15px;
}
.left_section {
  display: grid;
}
.left_section button {
  margin: 10px 0px;
  border: none;
  padding: 15px;
  outline: none;
  border-radius: 5px;
  background: #fff;
  text-align: left;
  margin: 10px 0px;
}
.left_section .active {
  margin: 10px 0px;
  border: none;
  padding: 15px;
  outline: none;
  border-radius: 5px;
  background: #4460b3 !important;
  text-align: left;
  color: #fff;
  margin: 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.left_section button {
  margin: 10px 0px;
  border: none;
  padding: 15px;
  outline: none;
  border-radius: 5px;
  background: #fff !important;
  text-align: left;
  margin: 10px 0px;
}

.serviceBox {
  font-family: "Poppins", sans-serif;
  padding: 25px;
  margin: 5px 0 0 5px;
  border-radius: 10px;
  box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease 0s;
}
.serviceBox:hover {
  background: #0d6efd;
}
.serviceBox .service-icon {
  color: #fb374d;
  font-size: 55px;
  margin: 0 0 20px;
  transition: all 0.3s ease 0s;
}
.serviceBox .title {
  color: #444;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 27px;
  text-transform: capitalize;
  margin: 0 0 10px;
  transition: all 0.3s ease 0s;
}
.serviceBox .description {
  color: #999;
  font-size: 14px;
  line-height: 27px;
  margin: 0 0 15px;
  transition: all 0.3s ease 0s;
}
.serviceBox .read-more {
  color: #fb374d;
  font-size: 15px;
  transition: all 0.3s ease 0s;
}
.serviceBox .read-more:hover {
  text-shadow: 4px 4px rgba(255, 255, 255, 0.3);
}
.serviceBox.cyan .service-icon,
.serviceBox.cyan .read-more {
  color: red;
}

.serviceBox.blue .service-icon,
.serviceBox.blue .read-more {
  color: red;
}
.serviceBox.blue:hover {
  background: #172e5a;
}
.serviceBox.orange .service-icon,
.serviceBox.orange .read-more {
  color: #f05d0d;
}
.serviceBox.orange:hover {
  background: #f05d0d;
}
.serviceBox:hover .service-icon,
.serviceBox:hover .title,
.serviceBox:hover .read-more {
color: #fff;
}
.serviceBox:hover .description {
  color: rgba(255, 255, 255, 0.8);
}
@media only screen and (max-width: 990px) {
  .serviceBox {
    margin: 0 0 30px;
  }
}


@media(max-width:768px){
  .about_container_Home{
    display: grid;
    grid-template-columns: 100% !important;
  }
  .about_container_Home p{
    text-align: justify;
    font-size: 12px;
  }

  .about_us_Boxes{
    display: grid;
    grid-template-columns: 100%;
    margin: 40px;
  }
}


</style>
