<template>
      <BannerOtherPages title="IT Support" />
      <div>
        <div class="container-fluid cont-wraper">
    <div class="container">
      <div class="site-contant">
        <div class="content">
        <div class="page-title"><h2>CORE IT & HEALTHCARE IT</h2></div>
            <p>LifeLayer is founded by IT Professional and a reputed doctor who is a Radiologist; we are providing information technology (IT) sales, support and services to healthcare service providers and our clients includes hospitals, nursing homes, medical clinics, laboratories, healthcare laboratories, healthcare research centers and institutes, radiologists, radiologic technologists, technologists, radiation technologists, radiographers, clinical labs and diagnostic laboratories, diagnostic imaging centers, hospital’s radiology department, teleradiology companies and teleradiology service providers, radiology research centers, radiology institutions and universities, mobile radiology services, teleradiology, referring physicians, outpatient diagnostic imaging centers, radiology service providers, PACS manufacturers, PACS system vendors and distributors, clinics and hospitals, medical laboratories, private radiology practices and teleradiologists.</p>
            <p>LifeLayer is not only offering IT support sales and services around-the-clock but also delivers sub-specialist radiology coverage and diagnostic imaging services. LifeLayer closely works with our customers to help them manage their medical, administrative, financial and legal aspects of a diagnostic or clinical imaging practice. Our information technology services and solutions boost our client’s performance.</p>
            <p>LifeLayer closely works with healthcare service providers and our clients includes hospitals, nursing homes, medical clinics, laboratories, healthcare laboratories, healthcare research centers and institutes, radiologists, radiologic technologists, technologists, radiation technologists, radiographers, clinical labs and diagnostic laboratories, diagnostic imaging centers, hospital’s radiology department, teleradiology companies and teleradiology service providers, radiology research centers, radiology institutions and medical universities, mobile radiology service providers, teleradiology, referring physicians, outpatient diagnostic imaging centers, radiology service providers, PACS manufacturers, PACS system vendors and distributors, clinics and hospitals.</p>
            <p>LifeLayer manage their datacenters, their customized applications and ready to provide them resolution for their customized/standardized HIS/RIS/PACS/EMR/EHR round-the-clock. LifeLayer working closely with our customers to achieve their goal and help them to manage their medical, financial, administrative and legal aspects of a diagnostic imaging practice and services.</p>
            </div>
      </div>
    </div>
  </div>
      </div>
</template>

<script>
  
import BannerOtherPages from '@/components/BannerOtherPages.vue';

export default {
    components: {
        BannerOtherPages
    }
}

</script>

<style scoped>
  
.banner_content{
background:url(../assets/img/carrer-img.jpg);
position: relative;
height: 450px;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: top;
display: grid;
place-items: center;
place-content: center;
}

</style>