<template>
  <div class="container mt-4">
    <h2>Blacklist Form</h2>
    <form @submit.prevent="submitForm" class="blacklist-form">
      <!-- Name -->
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input
          type="text"
          id="name"
          v-model="form.name"
          class="form-control"
          required
        />
      </div>

      <!-- Employee ID -->
      <div class="mb-3">
        <label for="employeeId" class="form-label">Employee ID</label>
        <input
          type="text"
          id="employeeId"
          v-model="form.employeeId"
          class="form-control"
          required
        />
      </div>

      <!-- Reason -->
      <div class="mb-3">
        <label for="reason" class="form-label">Reason</label>
        <textarea
          id="reason"
          v-model="form.reason"
          class="form-control"
          rows="3"
          required
        ></textarea>
      </div>

      <!-- Additional Info -->
      <div class="mb-3">
        <label for="additionalInfo" class="form-label">Additional Info</label>
        <textarea
          id="additionalInfo"
          v-model="form.additionalInfo"
          class="form-control"
          rows="3"
        ></textarea>
      </div>

      <!-- Date of Blacklisting -->
      <div class="mb-3">
        <label for="dateOfBlacklisting" class="form-label">Date of Blacklisting</label>
        <input
          type="date"
          id="dateOfBlacklisting"
          v-model="form.dateOfBlacklisting"
          class="form-control"
          required
        />
      </div>

      <!-- Upload Image -->
      <div class="mb-3">
        <label for="imageUpload" class="form-label">Upload Image</label>
        <input
          type="file"
          id="imageUpload"
          @change="handleImageUpload"
          class="form-control"
          accept="image/*"
        />
      </div>

      <!-- Social Media Links -->
      <div class="mb-3">
        <label for="linkedin" class="form-label">LinkedIn</label>
        <input
          type="url"
          id="linkedin"
          v-model="form.linkedin"
          class="form-control"
          placeholder="https://linkedin.com/in/username"
        />
      </div>
      <div class="mb-3">
        <label for="facebook" class="form-label">Facebook</label>
        <input
          type="url"
          id="facebook"
          v-model="form.facebook"
          class="form-control"
          placeholder="https://facebook.com/username"
        />
      </div>
      <div class="mb-3">
        <label for="instagram" class="form-label">Instagram</label>
        <input
          type="url"
          id="instagram"
          v-model="form.instagram"
          class="form-control"
          placeholder="https://instagram.com/username"
        />
      </div>

      <!-- Submit Button -->
      <button type="submit" class="btn btn-primary" :disabled="loading">
        {{ loading ? 'Submitting...' : 'Submit' }}
      </button>
    </form>

    <div v-if="message" class="alert mt-3" :class="alertClass">
      {{ message }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  data() {
    return {
      form: {
        name: '',
        employeeId: '',
        reason: '',
        additionalInfo: '',
        dateOfBlacklisting: '',
        image: null,
        linkedin: '',
        facebook: '',
        instagram: ''
      },
      loading: false,
      message: '',
      alertClass: ''
    };
  },
  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
      }
    },
    async submitForm() {
      this.loading = true;
      this.message = '';
      try {
        const formData = new FormData();
        formData.append('name', this.form.name);
        formData.append('employeeId', this.form.employeeId);
        formData.append('reason', this.form.reason);
        formData.append('additionalInfo', this.form.additionalInfo);
        formData.append('dateOfBlacklisting', this.form.dateOfBlacklisting);
        if (this.form.image) {
          formData.append('image', this.form.image);
        }
        formData.append('linkedin', this.form.linkedin);
        formData.append('facebook', this.form.facebook);
        formData.append('instagram', this.form.instagram);

        await axios.post('http://localhost:5000/blacklist/submitblacklist', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });

        toast.success('Upload Successfully', {
            position: 'bottom-right',
            autoClose: 3000,
          });
        this.alertClass = 'alert-success';
        this.resetForm();
      } catch (error) {
       
        this.alertClass = 'alert-danger';
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.form = {
        name: '',
        employeeId: '',
        reason: '',
        additionalInfo: '',
        dateOfBlacklisting: '',
        image: null,
        linkedin: '',
        facebook: '',
        instagram: ''
      };
      this.$refs.imageUpload.value = ''; // Reset file input
    }
  }
};
</script>

<style>
.blacklist-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.form-control {
  margin-bottom: 15px;
}

.alert {
  margin-top: 20px;
}
</style>
