<template>
  <div>
    <!-- Conditionally Render Views -->
    <div v-if="!showDetailsPage">
      <!-- Main Blacklist Page -->
      <BannerOtherPages title="Blacklist Employees" />

      <div class="container py-5">
        <!-- Heading and Description -->
        <h2 class="text-center fw-bold text-dark mb-4">Blacklist Employees</h2>
        <p class="text-center text-muted mb-5">
          A list of employees who have been blacklisted for various reasons. Use the
          search feature to find specific employees.
        </p>

        <!-- Loading Indicator -->
        <div v-if="loading" class="text-center">
          <div class="spinner-border text-primary" role="status"></div>
          <p>Loading...</p>
        </div>

        <!-- Error Message -->
        <div v-if="error" class="text-center text-danger">
          <p>{{ error }}</p>
        </div>

        <!-- Blacklist Cards -->
        <div class="row g-4">
          <div
            v-for="blacklistemp in blacklist"
            :key="blacklistemp._id"
            class="col-md-6 col-lg-4"
          >
            <div class="card shadow-lg border-0 h-100 rounded">
              <div class="card-body d-flex flex-column justify-content-between">
                <div class="d-flex align-items-center">
                  <img
                    :src="'http://localhost:5000/' + blacklistemp.image"
                    alt="Employee Image"
                    class="img-fluid rounded-circle employee-thumbnail"
                  />
                  <div class="ms-3">
                    <h5 class="card-title text-dark">{{ blacklistemp.name }}</h5>
                    <p class="text-muted mb-2">
                      Employee ID: {{ blacklistemp.employeeId }}
                    </p>
                    <p class="text-muted mb-2 text-truncate" style="max-width: 250px">
                      Reason: {{ blacklistemp.reason }}
                    </p>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-3">
                  <span class="badge bg-light text-muted">
                    {{ new Date(blacklistemp.dateOfBlacklisting).toLocaleDateString() }}
                  </span>
                  <!-- View Details Button -->
                  <button
                    class="btn btn-outline-primary btn-sm"
                    @click="viewDetails(blacklistemp)"
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Employee Details View -->
    <div v-else>
      <div class="container py-5">
        <!-- Back Button -->
        <button class="btn btn-secondary mb-4" @click="goBack">Back to List</button>

        <div class="details-card shadow-lg p-5 rounded">
          <div class="row align-items-center">
            <!-- Employee Image and Name -->
            <div class="col-md-4 text-center">
              <img
                :src="'http://localhost:5000/' + selectedEmployee.image"
                alt="Employee Image"
                class="img-fluid rounded-circle employee-image mb-3"
              />
              <h4 class="text-dark fw-bold">{{ selectedEmployee.name }}</h4>
            </div>
            <!-- Employee Details -->
            <div class="col-md-8">
              <h2 class="text-dark mb-4">Employee Details</h2>
              <ul class="list-unstyled">
                <li class="mb-3">
                  <h5 class="text-secondary">Employee ID:</h5>
                  <p>{{ selectedEmployee.employeeId }}</p>
                </li>
                <li class="mb-3">
                  <h5 class="text-secondary">Reason:</h5>
                  <p>{{ selectedEmployee.reason }}</p>
                </li>
                <li class="mb-3">
                  <h5 class="text-secondary">Additional Information:</h5>
                  <p>{{ selectedEmployee.additionalInfo }}</p>
                </li>
              </ul>
            </div>
          </div>

          <!-- Footer -->
          <footer class="footer mt-5 text-center">
            <p class="mb-1">
              <strong>Company:</strong> Lifelayer Health Solutions Pvt. Ltd. |
              <strong>Email:</strong>
              <a href="mailto:info@LifeLayer.org" class="text-primary"
                >info@LifeLayer.org</a
              >
            </p>
            <div class="social-icons mt-3">
              <a
                :href="selectedEmployee.instagram"
                v-if="selectedEmployee.instagram"
                class="p-2"
              >
                <i class="bi bi-instagram text-danger fs-4"></i>
              </a>
              <a
                :href="selectedEmployee.linkedin"
                v-if="selectedEmployee.linkedin"
                class="p-2"
              >
                <i class="bi bi-linkedin text-primary fs-4"></i>
              </a>
              <a
                :href="selectedEmployee.facebook"
                v-if="selectedEmployee.facebook"
                class="p-2"
              >
                <i class="bi bi-facebook text-primary fs-4"></i>
              </a>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BannerOtherPages from "@/components/BannerOtherPages.vue";
import axios from "axios";

export default {
  components: {
    BannerOtherPages,
  },
  data() {
    return {
      blacklist: [],
      loading: true,
      error: null,
      showDetailsPage: false,
      selectedEmployee: {},
    };
  },
  methods: {
    async fetchBlacklist() {
      try {
        const response = await axios.get("http://localhost:5000/blacklist/getblacklist");
        this.blacklist = response.data;
      } catch (err) {
        this.error = "Failed to fetch blacklist data. Please try again later.";
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    viewDetails(employee) {
      (this.selectedEmployee = {
        ...employee,
        instagram: employee.instagram || null,
        linkedin: employee.linkedin || null,
        facebook: employee.facebook || null,
      }),
        (this.showDetailsPage = true);
    },
    goBack() {
      this.showDetailsPage = false;
      this.selectedEmployee = {};
    },
  },
  mounted() {
    this.fetchBlacklist();
  },
};
</script>

<style scoped>
.employee-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.employee-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 5px solid #007bff;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.details-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

h2,
h5 {
  color: #343a40;
}

.footer {
  font-size: 1rem;
  color: #555;
}

.footer a {
  text-decoration: none;
  font-weight: bold;
}

.social-icons a {
  text-decoration: none;
}
</style>
