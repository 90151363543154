<template>

<BannerOtherPages title="Our Goal" />


</template>




<script>
import BannerOtherPages from '@/components/BannerOtherPages.vue';

export default {
    components: {
        BannerOtherPages
    }
}
</script>


<style scoped>
.banner_content{
/* background:url(../assets/img/our-goal.jpg); */
background-image: url(https://cdn.pixabay.com/photo/2020/04/09/10/15/desk-5020800_960_720.jpg);
position: relative;
height: 450px;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
display: grid;
place-items: center;
place-content: center;
}
.card {
  border-radius: 12px;
  background-color: #f8f9fa;
}
h2 {
  font-weight: bold;
}
.card-title {
  font-size: 1.5rem;
  color: #007bff;
}
.card-text {
  font-size: 1rem;
  color: #343a40;
}
.container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.page-title {
  text-align: center;
  margin-bottom: 20px;
}

.card {
  border-radius: 12px;
  background-color: #f8f9fa;
}

.card-title {
  font-size: 1.5rem;
  color: #007bff;
}

.card-text {
  font-size: 1rem;
  color: #343a40;
}

.site-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
}

.content p {
  line-height: 1.6;
}
</style>