<template>
      <BannerOtherPages title=" Our Team" />
     <div class="container-fluid cont-wraper">
    <div class="container">
      <div class="site-contant">
        <div class="content">
        <div class="page-title"><h2>ABOUT LIFELAYER FAMILY</h2></div>
			<p>We are ambitious about what we can do and the impact we can make. LifeLayer is made up on team of talented developers, designers, analysts, engineers, marketers and technologists, all dedicated and enjoy our work and working with the various skillsets and personalities within the team.</p>
			<p>Every person of the LifeLayer family believes strongly in the empowering power of knowledge, and we are happy to serve you when you thing “the issue is complicated to manage or critical to handle”, we are accepting the challenges and achieving the goal in timeframe. Every day we unite in doing our best to bridge the gap between those who have access to knowledge opportunity and those who do not. We are all looking forward to working with you on your next project.</p>
		</div>
      </div>
    </div>
  </div>
</template>
<script>
import BannerOtherPages from '@/components/BannerOtherPages.vue';
export default {
    components:{
        BannerOtherPages
    }
}
</script>
<style>

.banner_content{
background:url(../assets/img/lifelayer1-img.jpg);
position: relative;
height: 450px;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
display: grid;
place-items: center;
place-content: center;
}
</style>