<template>
<BannerOtherPages title="Technology Life" />
<div class="container-fluid cont-wraper">

        <div class="container">
            <div class="site-contant">
                <div class="content">
                    <div class="page-title">
                        <h2>TECHNOLOGY EXISTS - PLEASE USE IT</h2></div>
                    <p>Technology is increasing day by day in our life, we all depend on technology and we use various technologies to accomplish specific tasks in our lives. Today we have various emerging technologies which impact our lives in different manner. The use of technology is unmeasurable, technology has played a big role in many other fields like Healthcare, Job Creation and Data management.</p>
                    <p>And this technology will keep on changing basing on the demands of people and the market. So it’s our role to keep ourselves up-to-date with trending technology. How we use technology determines if it’s good or bad, helpful or harmful. Technology, itself, is neutral, but here we are who make it good or bad based on how we use it.</p>
                    <p>To achieve our goal, technology is being implemented in almost every section of our lives and business structures or it doesn’t matter which industry we are dealing in, technology will be of use in a certain manner. As the world keeps on developing, our technologies will be changed, what is working today might not work not be efficient tomorrow. So it is good or even best to stay update with new emerging technologies and learn how to use and embrace them in your daily life.</p>
                    <h3>1. Technology involve in our Business</h3>
                    <p>Today businesses can save money by using technology to perform certain tasks. When you compare the amount of money spent on hiring an individual to perform a certain task and to guarantee delivery on time, it is totally expensive. When it comes to technology a small business can scale out and deliver more with less human resource.</p>
                    <p>Like if I am in manufacturing business, instead of hiring so many people to work in certain sections, I can replace that labor with a ”PLC” programmed production line which will automate the process of manufacturing at all and the benefits of using a ”PLC” , is that productivity and accuracy will be better and first compared to using individuals.</p>
                    <p>Or if you wants to manage retail inventories, you don’t have to employ more people to manage and keep track the number of inventories. All they have to do is to use database systems which can be accessed remotely. This will make inventory data more accessible to whoever needs it with in the company and it will cut off delays in delivery of this information and makes the work.</p>
                    <p>I can sell or buy goods with the help of technology, technology has made buying and selling of good so flexible. With the introduction e-payment systems/gateway like Paypal.com and many more, people can easily purchase anything online without living the comfort of their homes.</p>
                    <p>Like your Bank in your pocket, without any hassle with the help of technology through net banking feature we can manage and maintain our bank online or even we can buy and sell any product online with the help of credit/debit cards which save our time this has made purchasing of items so easy without having any fears of getting robbed on the way, so in this case you don’t have to move with cash.</p>
                    <h3>2. Technology involve in our Communication</h3>
                    <p>Unlike in the past when communication was limited to writing letter and waiting for those postal services to deliver our message, with the help of technology our field of communications as improved and so easy, now we can draft our business messages and email or fax in a seconds without any delays and the recipient will get the message and they will reply us instantly.</p>
                    <p>We can do our business meetings so easily, with the introduction of video conferencing; we no longer have to worry to be late for a business meeting. Now with this video conferencing technology, we can be in the meeting in a virtual form and engage with our partners directly.</p>
                    <p>It is very simple to get feedback from our customers or clients, it has been aided by the introductions of online business to consumer portals like “Facebook or Amazon” or shopping carts. As a business person, we can get responses and suggestions from our customers via social network portals also.</p>
                    <h3>3. Technology involve in our Human Relation</h3>
                    <p>As of now people are getting more carried away with their work and carries. Today a lot is demanded or even everyone is busy to have time to find a relationship. Our technology has also filled this part with the help of technology we can connect and meet new people while at work using social media network technology. We can also use technology to find a new date without living our workplace. Now we are using mobile phones and using multiple software applications or apps to meet and connect with new and old friends. Social networks like Facebook, Twitter have played a big role in connecting both old and new relationships.</p>
                    <h3>4. Technology involve in our Education</h3>
                    <p>It has made a very big change in the education world, with the invention of technological gadgets and mobile apps which helps students learn easily. Now days you can access a full library via mobile app on any smart phone. Earlier in our past days students had to go to physical libraries to get the information what they need or require to getting education.</p>
                    <h3>5. Technology involve in our Agriculture</h3>
                    <p>In the agricultural industry, productivity is a key factor. Technology has changed the agricultural industry by replacing human labor with machines that are operated or controlled by people or other machines. If production is high the farmer will make more profits so technology has helped farmers replace the old ways of farming with machines that can do the job in time right from the day of planting to the day of harvesting.</p>
                    <p>As we remembered earlier an animal-drawn plow replaced the dig-ging stick. Then later, that plow was replaced by the horse drawn moldboard plow. Currently, we have the large tractors that pull complex tilling machines. But, even these machines are being replaced by no-till or minimum-till farming practices, which reduce the amount of work it takes to prepare the soil for planting.</p>
                    <h3>6. Technology involve to control and harness natural forces</h3>
                    <p>Natural forces affect and disrupt human life and daily economic activates. Just example and remember; Floods wash away farmland and homes, they carry out fertile top soil and disrupt the growth of crops. Also fires burn buildings, crops and forests which affect human life. However, technology has enabled humans build large dams which can harbor excess water and generate electricity as another power. Also fire is tamed to heat our homes and process industrial materials and Wind is being used to generate electricity also. We have converted solar power to solar energy which is being used in businesses and homes. All this is a result of using technology to control natural forces.</p>
                    <h3>7. Technology involve in our transportation system</h3>
                    <p>We are moving from one location to another with the help of transportation which is one of the basic areas of technological activity. Transportation technology has been changing since 1800 to the recent years. Both businesses and individuals have benefited from the new technologies in the travel industry. As you know time is money and important, so we must have first and efficient means of transport. We can’t try to imagine our life without well-developed transportation systems. I think of transportation in the same light as food, clothing and shelter. It has become a basic need, because we use advanced transport means like cars, trains and airplanes to go to work, to transport goods, to go shopping, to visit friends and families and so much more.</p>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
import BannerOtherPages from '@/components/BannerOtherPages.vue';
export default {
    components:{
        BannerOtherPages
    }
}
</script>
<style scoped>
.banner_content{
/* background:url(../assets/img/team-img.jpg); */
background-image: url(https://cdn.pixabay.com/photo/2017/08/06/01/45/robot-2587571_1280.jpg);
position: relative;
height: 450px;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
display: grid;
place-items: center;
place-content: center;
}
</style>