<template>
   <BannerOtherPages title="Chatting System" />
   <div class="container my-5">
    <!-- Header Section -->
    <header class="text-center mb-5">
      <h1 class="fw-bold">Real-Time Communication Made Simple</h1>
      <p class="lead text-muted">Boost your business communication with our advanced chat system.</p>
    </header>

    <!-- Features Section -->
    <section class="mb-5">
      <h2 class="fw-bold text-center mb-4">Why Choose Our Chat System?</h2>
      <div class="row g-4">
        <div class="col-md-4" v-for="(feature, index) in features" :key="index">
          <div class="card border-0 shadow">
            <div class="card-body text-center">
              <h5 class="card-title fw-bold">{{ feature.title }}</h5>
              <p class="card-text text-muted">{{ feature.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Benefits Section -->
    <section class="mb-5 text-center">
      <h2 class="fw-bold mb-4">Empowering Your Business Communication</h2>
      <ul class="list-unstyled">
        <li v-for="(benefit, index) in benefits" :key="index" class="mb-2">
          <i class="bi bi-check-circle-fill text-success me-2"></i>{{ benefit }}
        </li>
      </ul>
    </section>

    <!-- Call-to-Action Section -->
    <section class="text-center">
      <h2 class="fw-bold">Ready to Revolutionize Your Communication?</h2>
      <p class="lead">Discover how our chat system can transform your business interactions.</p>
      <button class="btn btn-primary btn-lg me-2" style="padding: 0px 20px;">Request a Demo</button>
      <button class="btn btn-outline-primary btn-lg">Learn More</button>
    </section>
  </div>
</template>

<script>
import BannerOtherPages from '@/components/BannerOtherPages.vue';
export default {
  components: {
        BannerOtherPages,
    },
  data() {
    return {
      features: [
        { title: "Real-Time Messaging", description: "Instant communication without delays." },
        { title: "Customizable Interface", description: "Adapt the chat system to match your brand identity." },
        { title: "Group Chats & Channels", description: "Enhance collaboration within teams or communities." },
        { title: "Secure Communication", description: "End-to-end encryption ensures privacy and data security." },
        { title: "Rich Media Support", description: "Share images, videos, files, and more." },
        { title: "Chatbot Integration", description: "Automate routine tasks and provide 24/7 support." },
        { title: "Cross-Platform Support", description: "Works seamlessly on web, mobile, and desktop." },
      ],
      benefits: [
        "Improved Customer Support: Resolve issues faster with real-time chats.",
        "Enhanced Productivity: Keep your team connected and coordinated.",
        "Cost-Effective Solution: Reduce operational costs with automated responses.",
      ],
      newMessage: "",
    };
  },

};
</script>

<style scoped>
.card {
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}
</style>
