
<template>
    <BannerOtherPages title="Contact Us" />
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="wrapper">
                    <div class="row no-gutters mb-5">
                        <div class="col-md-7">
                            <div class="contact-wrap w-100 p-md-5 p-4">
                                <h3 class="mb-4">Contact Us</h3>


                          <form @submit.prevent="submitForm" id="contactForm" name="contactForm" class="contactForm">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="label" for="name">Full Name</label>
                                                <input type="text" class="form-control" v-model="formData.name" placeholder="Name">
                                            </div>
                                        </div>
                                        <div class="col-md-6"> 
                                            <div class="form-group">
                                                <label class="label" for="email">Email Address</label>
                                                <input type="email" class="form-control" v-model="formData.email" placeholder="Email">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="label" for="subject">Subject</label>
                                                <input type="text" class="form-control" v-model="formData.subject" placeholder="Subject">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="label" for="message">Message</label>
                                                <textarea class="form-control" v-model="formData.message" cols="30" rows="4" placeholder="Message"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
    <div class="form-group">
        <label class="label" for="number">Number</label>
        <input 
            type="number" 
            class="form-control" 
            v-model="formData.number" 
            placeholder="Enter a number" 
        />
    </div>
</div>

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <input type="submit" value="Send Message" class="btn btn-primary">
                                                <div class="submitting"></div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-md-5 d-flex align-items-stretch">
                            <div id="map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d28061.278674602665!2d77.0173709989104!3d28.459668949636043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1731140720465!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="dbox w-100 text-center">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <span class="fa fa-map-marker"></span>
                        </div>
                        <div class="text">
                        <p><span>Address: </span> Sector-43 Gurugav Haryana</p>
                      </div>
                  </div>
                        </div>
                        <div class="col-md-3">
                            <div class="dbox w-100 text-center">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <span class="fa fa-phone"></span>
                        </div>
                        <div class="text">
                        <p><span>Phone: </span> <a href="tel://1234567920">+ 1235 2355 98</a></p>
                      </div>
                  </div>
                        </div>
                        <div class="col-md-3">
                            <div class="dbox w-100 text-center">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <span class="fa fa-paper-plane"></span>
                        </div>
                        <div class="text">
                        <p><span>Email: </span> <a href="mailto:info@yoursite.com">info@LifeLayer.org</a></p>
                      </div>
                  </div>
                        </div>
                        <div class="col-md-3">
                            <div class="dbox w-100 text-center">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <span class="fa fa-globe"></span>
                        </div>
                        <div class="text">
                        <p><span>Website: </span> <router-link to="/">lifelayer.org</router-link></p>
                      </div>
                  </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
import BannerOtherPages from '@/components/BannerOtherPages.vue';
import axios from 'axios';
export default {
    components: {
        BannerOtherPages,
    },

    data(){
        return{
            formData: {
                name: '',
                email: '',
                subject: '',
                message: '',
                number: '',
            },
            formMessage: {
                success: '',
                warning: ''
            }
        }
    },
    methods: {
        async submitForm() {
            try {
               await axios.post('http://localhost:5000/contact/postQuery', this.formData);
                this.formMessage.success = 'Your message was sent, thank you!';
                toast.success("Your query have been sent. Our team will reach you soon..!!", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
        });
                this.formMessage.warning = '';
                this.clearForm();
            } catch (error) {
                toast.error("Failed To Send Your Message. Please try again later", {
                position: "bottom-right",
                width: "400px",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
        });
                this.formMessage.warning = 'Failed to send your message. Please try again.';
                this.formMessage.success = '';
            }
        },
        clearForm() {
            this.formData.name = '';
            this.formData.email = '';
            this.formData.subject = '';
            this.formData.message = '';
            this.formData.number = '';

        }
    }
}
</script>

<style scoped>
.banner_content{
background:url(../assets/img/lifelayer11-img.jpg);
position: relative;
height: 450px;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
display: grid;
place-items: center;
place-content: center;
}


.contactForm {
    max-width: 800px;
    margin: 0 auto;
    background: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.contactForm .row {
    display: flex;
    flex-wrap: wrap;
}

.contactForm .col-md-6,
.contactForm .col-md-12 {
    padding: 10px;
}

.contactForm .form-group {
    margin-bottom: 20px;
    position: relative;
}

.contactForm .label {
    font-weight: bold;
    color: #333;
    display: block;
    margin-bottom: 5px;
    font-size: 0.9rem;
}

.contactForm .form-control {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    transition: all 0.3s ease;
    outline: none;
}

.contactForm .form-control:focus {
    border-color: #007bff;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.contactForm textarea.form-control {
    resize: none;
    min-height: 100px;
}

.contactForm input[type="submit"] {
    background: #007bff;
    color: #fff;
    padding: 12px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.contactForm input[type="submit"]:hover {
    background: #0056b3;
}

.contactForm .submitting {
    display: none; /* can be toggled with JavaScript when needed */
    font-size: 0.9rem;
    color: #666;
    margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .contactForm {
        padding: 20px;
    }

    .contactForm .col-md-6,
    .contactForm .col-md-12 {
        width: 100%;
        padding: 0;
    }
}

</style>