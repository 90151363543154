<template>
  <div class="dashboard d-flex">
    <!-- Sidebar -->
    <div class="sidebar bg-light p-3">
      <h5 class="text-primary mb-4">Lifelayer</h5>
      <ul class="nav flex-column">
        <li class="nav-item">
          <a
            class="nav-link text-dark"
            :class="{ 'fw-bold': currentView === 'dashboard' }"
            @click="toggleView('dashboard')"
          >
            Dashboard
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-dark"
            :class="{ 'fw-bold': currentView === 'blacklist' }"
            @click="toggleView('blacklist')"
          >
            Blacklist
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-dark"
            :class="{ 'fw-bold': currentView === 'contact query' }"
            @click="toggleView('contact query')"
          >
            Contact Query
          </a>
        </li>
      </ul>
    </div>

    <!-- Main Content -->
    <div class="content flex-grow-1 p-4">
      <!-- Conditionally Render Views -->
      <div v-if="currentView === 'dashboard'">
        <!-- Dashboard Cards Section -->
        <div class="row g-4">
          <div class="col-md-4">
            <div class="card text-center p-4 shadow" v-if="totalContactQuery !== null">
              <i class="bi bi-chat-square-dots fs-1 text-primary"></i>
              <h3 class="mt-3">Total Queries</h3>
              <p class="fs-4">{{ totalContactQuery }}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card text-center p-4 shadow" v-if="totalBlacklistEmployee !== null">
              <i class="bi bi-person-x fs-1 text-danger"></i>
              <h3 class="mt-3" >Blacklist Employees</h3>
              <p class="fs-4">{{totalBlacklistEmployee }}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card text-center p-4 shadow">
              <i class="bi bi-eye fs-1 text-success"></i>
              <h3 class="mt-3">Total Visits</h3>
              <p class="fs-4">{{ totalVisits }}</p>
            </div>
            
          </div>
          
        </div>
        
      </div>

      <div v-else-if="currentView === 'blacklist'">
        <!-- Blacklist Page -->
        <h2 class="text-center fw-bold text-dark mb-4">Blacklist Employees</h2>

        <div v-if="!showDetailsPage">
      <!-- Main Blacklist Page -->
      <BannerOtherPages title="Blacklist Employees" />

      <div class="container py-5">
        <!-- Heading and Description -->
       
        <!-- Loading Indicator -->
        <div v-if="loading" class="text-center">
          <div class="spinner-border text-primary" role="status"></div>
          <p>Loading...</p>
        </div>

        <!-- Error Message -->
        <div v-if="error" class="text-center text-danger">
          <p>{{ error }}</p>
        </div>

        <!-- Blacklist Cards -->
        <div class="row g-4">
          <div
            v-for="blacklistemp in blacklist"
            :key="blacklistemp._id"
            class="col-md-6 col-lg-4"
          >
            <div class="card shadow-lg border-0 h-100 rounded">
              <div class="card-body d-flex flex-column justify-content-between">
                <div class="d-flex align-items-center">
                  <img
                    :src="'http://localhost:5000/' + blacklistemp.image"
                    alt="Employee Image"
                    class="img-fluid rounded-circle employee-thumbnail"
                  />
                  <div class="ms-3">
                    <h5 class="card-title text-dark">{{ blacklistemp.name }}</h5>
                    <p class="text-muted mb-2">
                      Employee ID: {{ blacklistemp.employeeId }}
                    </p>
                    <p class="text-muted mb-2 text-truncate" style="max-width: 250px">
                      Reason: {{ blacklistemp.reason }}
                    </p>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-3">
                  <span class="badge bg-light text-muted">
                    {{ new Date(blacklistemp.dateOfBlacklisting).toLocaleDateString() }}
                  </span>
                  <!-- View Details Button -->
                  <button
                    class="btn btn-outline-primary btn-sm"
                    @click="viewDetails(blacklistemp)"
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Employee Details View -->
    <div v-else>
      <div class="container py-5">
        <!-- Back Button -->
        <button class="btn btn-secondary mb-4" @click="goBack">Back to List</button>

        <div class="details-card shadow-lg p-5 rounded">
          <div class="row align-items-center">
            <!-- Employee Image and Name -->
            <div class="col-md-4 text-center">
              <img
                :src="'http://localhost:5000/' + selectedEmployee.image"
                alt="Employee Image"
                class="img-fluid rounded-circle employee-image mb-3"
              />
              <h4 class="text-dark fw-bold">{{ selectedEmployee.name }}</h4>
            </div>
            <!-- Employee Details -->
            <div class="col-md-8">
              <h2 class="text-dark mb-4">Employee Details</h2>
              <ul class="list-unstyled">
                <li class="mb-3">
                  <h5 class="text-secondary">Employee ID:</h5>
                  <p>{{ selectedEmployee.employeeId }}</p>
                </li>
                <li class="mb-3">
                  <h5 class="text-secondary">Reason:</h5>
                  <p>{{ selectedEmployee.reason }}</p>
                </li>
                <li class="mb-3">
                  <h5 class="text-secondary">Additional Information:</h5>
                  <p>{{ selectedEmployee.additionalInfo }}</p>
                </li>
              </ul>
            </div>
          </div>

          <!-- Footer -->
          <footer class="footer mt-5 text-center">
            <p class="mb-1">
              <strong>Company:</strong> Lifelayer Health Solutions Pvt. Ltd. |
              <strong>Email:</strong>
              <a href="mailto:info@LifeLayer.org" class="text-primary"
                >info@LifeLayer.org</a
              >
            </p>
            <div class="social-icons mt-3">
              <a
                :href="selectedEmployee.instagram"
                v-if="selectedEmployee.instagram"
                class="p-2"
              >
                <i class="bi bi-instagram text-danger fs-4"></i>
              </a>
              <a
                :href="selectedEmployee.linkedin"
                v-if="selectedEmployee.linkedin"
                class="p-2"
              >
                <i class="bi bi-linkedin text-primary fs-4"></i>
              </a>
              <a
                :href="selectedEmployee.facebook"
                v-if="selectedEmployee.facebook"
                class="p-2"
              >
                <i class="bi bi-facebook text-primary fs-4"></i>
              </a>
            </div>
          </footer>
        </div>
      </div>
    </div>


        <!-- Blacklist Cards -->
    
                  
              
     
<button @click="openUploadForm">Add Upload</button>

<div v-show="showForm">
<blacklist_form />

</div>

      </div>

      <!-- Contact Query Profile Card -->
      <div v-if="currentView === 'contact query'" class="profile-card">
        <div class="card" v-for="showData in fetchContactData" :key="showData._id">
          <div class="card-body">
            <p class="card-text">
              <strong>Subject:</strong> {{ showData.subject }}<br />
              <strong>Name:</strong> {{ showData.name }}<br />
              <strong>Email:</strong> {{ showData.email }}<br />
              <strong>Message:</strong> {{ showData.message }}<br />
              <strong>Phone Number:</strong> {{ showData.number }}
            </p>
           
          </div>
          
          
        </div>
      </div>
      <!-- <div class="add_form_button">

     <button style="position:absolute; right: 17px; bottom: 8px; padding:
     10px;">Upload blacklist-form</button>
      </div> -->
      
    </div>

  </div>
</template>

<script>
import axios from "axios";
import blacklist_form from '@/admin_forms/blacklist_form'

export default {
  name: "ProfileCard",
  components: {
    blacklist_form
  },
  data() {
    return {
      currentView: "dashboard",
      showDetailsPage: false, 
      totalQueries: 20, 
      blacklistCount: 6, 
      totalVisits: 1000, 
      blacklist: '', 
      selectedEmployee: {},
      loading: false,
      error: null,
      fetchContactData: '',
      totalBlacklistEmployee: '',
      totalContactQuery: '',
      showForm: false,
    };
  },
  methods: {
    openUploadForm() {
   this.showForm = !this.showForm;
    },
    toggleView(view) {
      this.currentView = view;
      if (view === "blacklist") {
        this.fetchBlacklist();
      }
    },
    async fetchBlacklist() {
      this.loading = true;
      try {
        const response = await axios.get("http://localhost:5000/blacklist/getblacklist");
        this.blacklist = response.data;
        this.totalBlacklistEmployee = response.data.length || 0;
      } catch (err) {
        this.error = "Failed to fetch blacklist data.";
        this.totalBlacklistEmployee = 0;
      } finally {
        this.loading = false;
      }
    },
    async fetchContactQuery() {
      this.loading = true;
      try {
        const response = await axios.get("http://localhost:5000/contact/getContactQuery");
        this.fetchContactData = response.data;
        this.totalContactQuery = response.data.length || 0;
      } catch (err) {
        this.error = "Failed to fetch Contact data.";
        this.totalContactQuery = 0;
      } finally {
        this.loading = false;
      }
    },
    viewDetails(employee) {
      this.selectedEmployee = employee;
      this.showDetailsPage = true;
    },
    goBack() {
      this.showDetailsPage = false;
      this.selectedEmployee = {};
    },
  },
  created() {
    if (this.currentView === "blacklist") {
      this.fetchBlacklist();
}
this.fetchContactQuery();

  },
};
</script>

<style scoped>
.sidebar {
  width: 310px;
  height: 100vh;
  border-right: 1px solid #ddd;
}
.employee-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.employee-image {
  width: 150px;
  height: 150px;
  border: 5px solid #007bff;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.details-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.card {
  border: none;
  margin: 0px 20px;

}
.card i {
  font-size: 3rem;
}

/* contact */

/* Profile Card Styles */
.profile-card {
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  padding: 10px;
  display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}


.card {
  width: 100%;
  max-width: 600px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  transition: all 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.card-body {
  padding: 20px;
  color: #333;
  font-family: "Arial", sans-serif;
}

.card-text {
  line-height: 1.6;
  font-size: 16px;
}

.card-text strong {
  color: #2d87f0; /* Blue color for labels */
}

/* Styling for individual fields */
.card-text strong + br {
  margin-bottom: 5px;
}

.card-text br {
  margin-bottom: 10px;
}

/* For mobile responsiveness */
@media (max-width: 768px) {
  .card {
    max-width: 100%;
  }
}
</style>
