<template>
  <div class="hero_section" data-aos="zoom-in" data-aos-duration="1000">
    <div class="video_container">
      <video src="../assets/img/bg_video copy.mp4" autoplay muted loop></video>
      <div class="video-overlay">
        <div class="content">
          <h1>
            Welcome to Lifelayer <br />
            <span class="content_span">Healthcare Solutions Pvt. Ltd.</span>
          </h1>
          <p
            style="display: flex;justify-content: center;align-items: center;text-align: center;place-items: center;margin-top: -44px;
font-size: 15px; padding: 10px 73px; margin-top: -57px;}"
          >
            Lifelayer Health Solutions Pvt. Ltd. is a product-based IT company
            specializing in healthcare solutions. It focuses on innovative digital tools
            and applications to enhance patient care and streamline healthcare processes."
          </p>
          <div class="content-buttons"></div>
          <RouterLink to="/About_Us"
            ><a class="btns_banner" style="color: #fff !important; text-decoration: none"
              >About Us</a
            ></RouterLink
          >
          <RouterLink to="/Contact_Us"
            ><a class="btns_banner_contact" style="text-decoration: none"
              >Contact Us</a
            ></RouterLink
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style>
@import "@/css/animate.css";
@import "@/css/lightbox.min.css";
@import "@/css/style.css";
@import "@/css/style2.css";

.video-overlay .content h1{
  font-size: 56px;
}
.content_span{
font-size: 20px;
position: relative;
top: -40px;
left: 135px;
  }

  


  /* <------responsive page code-----> */

@media screen and (max-width: 767px){
  .video-overlay .content {
      max-width: 660px;
      width: 660px;
  }
  .video-overlay .content h1{
    font-size: 40px;
  }
.content_span{
font-size: 20px;
position: relative;
top: -30px;
left: 80px;
  }
}
</style>
