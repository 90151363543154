<template>
    <BannerOtherPages title="Email Marketing" />
    <div>
      <!-- About Us Content Section -->
  <div class="container-fluid cont-wraper">
    <div class="container">
      <div class="site-contant">
        <div class="content">
            <div class="page-title"><h2>YOUR OWN EMAIL MARKETING OR DIGITAL MARKETING</h2></div>
            <p>Do email marketing a priority for your business or why you are looking for email marketing, do you have any application, any plugin or tool installed which solves your email marketing issues. Our Email marketing system is one of the most cost-effective, extremely easy to use and incredibly powerful to manage, gives you full control and allows you to establish direct contact with your audience.</p>
            <p>For example, every day you are sending and receiving emails by using applications like Gmail, Yahoo or Outlook. Good as a personal, these tools are working well for sending one-off messages to individuals and groups and if you abuse the BCC field, you can send 100 individuals email without annoying everyone. But what about business email addresses and how you cadre for larger groups and scheduled sends to your dedicated audiences, you'll need another option something that makes it as easy to send an email to everyone as it is to send a message to one person. That's why, email marketing is in this picture.</p>
            <p>There are a variety of email marketing platforms are available in the market, you may not have to fork over a dime to try them out. For few hundred subscribers, some are completely free and providing the perfect opportunity to get started, now build your subscriber list and figure out what email marketing tactics resonate with your audience before you go big. We are not here for comparing others, be noticed, it may be possible you lost your clientele if you are using third party plugins, suggesting you before investing be careful. Here needs to judge what better way to keep in touch with your clients, prospects and contacts than through email?</p>
            <p>Emails about the latest happenings at your company can get boring. To capture your customer attention, you should write emails that are focused on their interests, not yours. Let’s starts to draft and design an email newsletters with a full-featured email editor and send them to your customer lists, which you can add via csv files.</p>
            </div>
      </div>
    </div>
  </div>
    </div>
</template>
<script>
import BannerOtherPages from '@/components/BannerOtherPages.vue';
export default {
    components:{
        BannerOtherPages
    }
}
</script>