import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './views/HomePage.vue';
import AboutUs from './views/AboutUs.vue';
import ContactUs from './views/ContactUs.vue';
import Business_Philo from './views/Business_Philo.vue';
import Career_Page from './views/Career_Page.vue';
import Our_Goal from './views/Our_Goal.vue';
import Our_Team from './views/Our_Team.vue';
import Technology_inourLife from './views/Technology_inour life.vue'; 
import Healthcare_Consulting from './views/Healthcare_Consulting.vue';
import webConsulting from './views/webConsulting.vue';
import healthcareItConsulting from './views/healthItConsoluting.vue'; // Fixed typo
import healthcareERP from './views/healthcareERP.vue';
import radiology_On_Mobile from './views/radiologyOnMobile.vue';
import appointmentCalender from './views/appointmentCalender.vue';
import emailMarketing from './views/emailMarketing.vue';
import ItSupport from './views/ItSupport.vue';
import oracleDataBaseSupport from './views/oracleDataBaseSupport.vue';
import ERP_Consulting from './views/ERP_Consulting.vue';
import Digital_Marketing from './views/Digital_Marketing.vue';
import socialMediaPlayform from './views/socialMediaPlayform.vue';
import EducationERP from './views/EducationERP.vue';
import JobBoard from './views/JobBoard.vue';
import HealthTracker from './views/HealthTracker.vue';
import Development_process from './views/Development_process.vue';
import ChatingSystem from './views/ChatingSystem.vue';
import BlockPage from './views/BlockPage.vue';
import contact_Home from './components/contact_Home.vue';
import blacklisting_form from './admin_forms/blacklist_form.vue';
import Login_form from './admin_forms/Login_Form.vue';
import Dash_Board from './admin_forms/Dash_Board.vue';
import error_page from './views/error_page.vue';

const routes = [
  { name: 'HomePage', path: '/', component: HomePage },
  { name: 'About Us', path: '/About_Us', component: AboutUs },
  { name: 'Contact Us', path: '/Contact_Us', component: ContactUs },
  { name: 'Business Philosophy', path: '/Business_Philosophy', component: Business_Philo },
  { name: 'Career_Page', path: '/Career', component: Career_Page },
  { name: 'Our_Goal', path: '/Our_Goal', component: Our_Goal },
  { name: 'Our_Team', path: '/OurTeam', component: Our_Team },
  { name: 'Technology_life', path: '/Technology_life', component: Technology_inourLife },
  { name: 'Healthcare_Consulting', path: '/Healthcare_Consulting', component: Healthcare_Consulting },
  { name: 'Web_Consulting', path: '/Web_Consulting', component: webConsulting },
  { name: 'Development_process', path: '/Development_process', component: Development_process },
  { name: 'healthcareItConsulting', path: '/healthcareItConsulting', component: healthcareItConsulting },
  { name: 'healthcareERP', path: '/healthcareERP', component: healthcareERP },
  { name: 'radiologyOnMobile', path: '/radiologyOnMobile', component: radiology_On_Mobile },
  { name: 'appointmentCalender', path: '/appointmentCalender', component: appointmentCalender },
  { name: 'emailMarketing', path: '/emailMarketing', component: emailMarketing },
  { name: 'ItSupport', path: '/ItSupport', component: ItSupport },
  { name: 'oracleDataBaseSupport', path: '/oracleDataBaseSupport', component: oracleDataBaseSupport },
  { name: 'ERP Consulting', path: '/ERP_Consulting', component: ERP_Consulting },
  { name: 'Digital_Marketing', path: '/Digital_Marketing', component: Digital_Marketing },
  { name: 'socialMediaPlayform', path: '/socialMediaPlayform', component: socialMediaPlayform },
  { name: 'EducationERP', path: '/EducationERP', component: EducationERP },
  { name: 'JobBoard', path: '/JobBoard', component: JobBoard },
  { name: 'HealthTracker', path: '/HealthTracker', component: HealthTracker },
  { name: 'ChattingSystem', path: '/Chatting_System', component: ChatingSystem },
  { name: 'BlockPage', path: '/BlockPage', component: BlockPage },
  { name: 'contact_Home', path: '/contact_Home', component: contact_Home },
  { name: 'blacklisting_form', path: '/blacklisting_form', component: blacklisting_form },
  { name: 'Login_form', path: '/admin', component: Login_form, meta: { showHeaderFooter: false } },
  { name: 'error_page', path: '/Page_Not_Found', component: error_page, meta: { showHeaderFooter: false } },
  { name: 'Dash_Board', path: '/Admin_Dashboard', component: Dash_Board, meta: { requiresAuth: true, showHeaderFooter:false } },
  { path: '/:catchAll(.*)', component: error_page, meta: { showHeaderFooter: false } }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  const tokenExpiration = localStorage.getItem('tokenExpiration');
  const currentTime = new Date().getTime();
  if (token && tokenExpiration && currentTime < tokenExpiration) {
    if (to.path === '/') {
      next('/Admin_Dashboard');
    } else {
      next();
    }
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');

    if (to.matched.some(record => record.meta.requiresAuth)) {
      next({ path: '/admin', query: { redirect: to.fullPath } });
    } else {
      next();
    }
  }
});

export default router;
