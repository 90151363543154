<template>
<div class="banner_content">
  <!-- Overlay Layer -->
  <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(68, 96, 179, 0.3)); z-index: 99;">
</div>
  
  <!-- Content Layer -->
  <div style="position: relative; z-index: 999;">
    <h1 style="margin: 0; color: white;">{{ title }}</h1>
  </div>
</div>

</template>

<script>
export default {
    props: {
        title: String
    }
}
</script>

<style>

.banner_content{
background:url(../assets/img/about_us.jpg);
position: relative;
height: 430px !important;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
display: grid;
place-items: center;
place-content: center;
}

</style>