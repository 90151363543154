
<template>

    <BannerOtherPages title="Education ERP"/>
    <div class="container mt-5">
    <div class="page-title mt-4" style="width:100%; ">
   
    <h2>WEB &amp; HYBRID APPLICATION</h2></div>
    </div>
    <!-- Dashboard Section -->
    <section class="container py-5">
      <h2 class="text-center mb-4">Dashboard</h2>
      <div class="row g-4">
        <div class="col-md-3">
          <div class="card text-center shadow h-100">
            <div class="card-body">
              <i class="bi bi-people-fill display-4 text-primary"></i>
              <h5 class="card-title mt-3">Students</h5>
              <p class="card-text">1,250 Enrolled</p>
              <button class="btn btn-outline-primary w-100">View Details</button>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card text-center shadow h-100">
            <div class="card-body">
              <i class="bi bi-journal-bookmark-fill display-4 text-success"></i>
              <h5 class="card-title mt-3">Courses</h5>
              <p class="card-text">45 Active</p>
              <button class="btn btn-outline-success w-100">Manage</button>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card text-center shadow h-100">
            <div class="card-body">
              <i class="bi bi-briefcase-fill display-4 text-warning"></i>
              <h5 class="card-title mt-3">Faculty</h5>
              <p class="card-text">75 Members</p>
              <button class="btn btn-outline-warning w-100">View Faculty</button>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card text-center shadow h-100">
            <div class="card-body">
              <i class="bi bi-bell-fill display-4 text-danger"></i>
              <h5 class="card-title mt-3">Announcements</h5>
              <p class="card-text">3 New</p>
              <button class="btn btn-outline-danger w-100">View</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Announcements Section -->
    <section class="bg-light py-5">
      <div class="container">
        <h2 class="text-center mb-4">Latest Announcements</h2>
        <div class="list-group">
          <div
            v-for="(announcement, index) in announcements"
            :key="index"
            class="list-group-item list-group-item-action"
          >
            <h5 class="mb-1">{{ announcement.title }}</h5>
            <small class="text-muted">{{ announcement.date }}</small>
            <p class="mb-0">{{ announcement.details }}</p>
          </div>
        </div>
      </div>
    </section>

    
</template>

<script>
import BannerOtherPages from '@/components/BannerOtherPages.vue';

export default {
    components: {
        BannerOtherPages,
    },
    data() {
    return {
      announcements: [
        {
          title: "Midterm Exams Scheduled",
          date: "November 20, 2024",
          details: "Midterm exams will begin on December 5, 2024. Check your timetable for details.",
        },
        {
          title: "Faculty Meeting",
          date: "November 19, 2024",
          details: "A faculty meeting is scheduled for November 25, 2024, in the conference hall.",
        },
        {
          title: "Holiday Notice",
          date: "November 18, 2024",
          details: "The institution will remain closed on November 30, 2024, for a public holiday.",
        },
      ],
    };
  },
}
</script>
<style scoped>

.banner_content{
background:url(https://cdn.pixabay.com/photo/2021/01/09/17/45/computer-5903052_1280.jpg);
position: relative;
height: 450px;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
display: grid;
place-items: center;
place-content: center;
}
</style>
