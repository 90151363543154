<template>
  <header id="header" class="fixed-top d-flex align-items-center" style="z-index: 99999;">
    <div class="container">
      <div class="header-container d-flex align-items-center justify-content-between">
        <div class="logo">
          <router-link to="/">
            <img
              style="border-radius: 50%;position: relative; top:
              -2px; width: 80% !important"
              src="../assets/img/lifelayer_logo.svg"
              alt=""
              class="img-fluid"/> <span style="position: absolute;
            top: 17px;
            left: 53px;
            font-size: 20px;">Lifelayer</span>
          </router-link>
           
        </div>

        <nav id="navbar" class="navbar">
          <ul>
            <li>
              <router-link
                :to="`/`"
                class="nav-link scrollto"
                :class="{ active: isActive('/') }"
                >Home</router-link
              >
            </li>
            <li class="dropdown">
              <a href="#" :class="{ active: isActive('') }">
                <span>About Us</span> <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li>
                  <router-link to="/About_Us" :class="{ active: isActive('/About_Us') }"
                    >About Us</router-link
                  >
                </li>
                 <li>
                  <router-link to="/Technology_life" :class="{ active: isActive('/Technology in our life') }">Technology in our life</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/Business_Philosophy"
                    :class="{ active: isActive('/Business_Philosophy') }"
                    >Business Philosophy
                    <!-- <p>Writing about a company’s business philosophy is very complicated. We are not hiding anything from our client but YES hiding their business secrecy, as LifeLayer knows which is very much important from all aspects.</p> -->
                  </router-link>
                </li>
                <li class="dropdown">
                  <router-link to="/OurTeam">
                    Our Team
                    <!-- <i class="bi bi-chevron-right"></i> -->
                 </router-link>
                 
                </li>
                <li>
                  <router-link to="/Our_Goal" :class="{ active: isActive('/Our_Goal') }">Our Goal</router-link >
                </li>
              </ul>
            </li>
            <li class="dropdown">
              <a href="#" :class="{ active: isActive('/HireUs') }">
                <span>Hire Us</span> <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li>
                  <router-link
                    to="/Healthcare_Consulting"
                    :class="{ active: isActive('/Healthcare_Consulting') }"
                    >Healthcare Consulting</router-link >
                </li>
                <li>
                  <router-link
                    to="/healthcareItConsulting"
                    :class="{ active: isActive('/healthcareItConsulting') }"
                    >Healthcare IT Consulting</router-link  >
                </li>
                <li>
                  <router-link
                    to="/ERP_Consulting"
                    :class="{ active: isActive('/ERP_Consulting') }"
                    >ERP Consulting</router-link >  </li>
                <li>
                  <router-link
                    to="/Digital_Marketing"
                    :class="{ active: isActive('/Digital_Marketing') }"
                    >Digital Marketing</router-link >
                </li>
                <li>
                  <router-link
                    to="/Web_Consulting"
                    :class="{ active: isActive('/Web_Consulting') }"
                    >Web Consulting</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/ItSupport"
                    :class="{ active: isActive('/ItSupport') }"
                    >IT Support</router-link
 >
                </li>


                <li>
                  <router-link
                    to="/oracleDataBaseSupport"
                    :class="{ active: isActive('/oracleDataBaseSupport') }"
                    >Database Management</router-link
                  >
                </li>
              </ul>
            </li>

            <li class="dropdown">
              <a href="#" :class="{ active: isActive('/awards') }">
                <span>Education</span> <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li>
                  <router-link to="/healthcareERP">Healthcare ERP</router-link>
                </li>
                <li>
                  <router-link to="/radiologyOnMobile">Radiology on mobile</router-link>
                </li>
                <li>
                  <router-link to="/appointmentCalender">Appointment calendar</router-link>
                </li>
                <li>
                <router-link to="/emailMarketing">Email Marketing</router-link>
              </li>
              <li>
                <router-link to="/socialMediaPlayform">Social Media Platform</router-link>
              </li>
              <li>
                <router-link to="/EducationERP">Education ERP</router-link>
              </li>
              <li>
                <router-link to="/JobBoard">job Board</router-link>
              </li>
              <li>
                <router-link to="/HealthTracker">Health Tracker</router-link>
              </li>
              </ul>
            </li>


            <li>
              <router-link
                to="/Career"
                class="nav-link scrollto"
                :class="{ active: isActive('/Career') }"
                >Career</router-link
              >
            </li>

            <!-- <li class="dropdown">
              <a href="/OurTeam" :class="{ active: isActive('/Our Team') }">
                <span>Our Team</span> <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li><router-link to="/NAPCON2020">NAPCON 2020</router-link></li>
                <li><router-link to="/NAPCON2021">NAPCON 2021</router-link></li>
              </ul>
            </li> -->
            <router-link to="/Contact_Us"
              ><a class="getstarted"> Get a quote?</a></router-link
            >
          </ul>

          <i class="bi bi-list mobile-nav-toggle" @click="toggleMobileMenu"></i>
        </nav>
      </div>

      <div
        class="pop-up-contact"
        v-show="showpopup"
        data-aos="zoom-in-up"
        data-aos-delay="50"
        data-aos-duration="1000"
      >
        <div class="popup-form">
          <i class="bi bi-x-circle-fill" @click="popupfunction()"></i>
          <form @submit.prevent="contactForm" class="formsubmit">
            <div class="form-input">
              <input type="text" placeholder="Full Name" required />
              <input type="email" placeholder="Email" required />
            </div>
            <br /><br />

            <div class="form-input">
              <input type="text" placeholder="Contact No." required />
              <input type="text" placeholder="Subject" required />
            </div>
            <br /><br />

            <div>
              <input type="text" placeholder="Send Message..." class="input-message" />
            </div>
            <br /><br />

            <div class="submit">
              <input type="submit" value="Send" />
            </div>
          </form>
        </div>
      </div>
      <!-- End Header Container -->
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      showpopup: false,

      submenu_Items: [
        { submenuItems: "Parke Davis/NCCP(I) Chest Oration" },
        { submenuItems: "Raman Viswanathan Memorial Lecture" },
        { submenuItems: "NCCP(I) Cipla Chest Oration" },
        { submenuItems: "NCCP(I) German Remedies Chest Oration" },
        { submenuItems: "NCCP(I) Lupin Chest Oration" },
        { submenuItems: "Young Scientist Award of NCCP(I)" },
      ],
      award_Menu_Data: [
        {
          heading: "Parke Davis/NCCP(I) Chest Oration",
          heading2:
            "(A) WARNER NCCP (I) ORATION (renamed as PARKE-DAVIS NCCP (I) CHEST ORATION in 1992)",
          paragraph:
            "The Association / College has awards to encourage research activities in the field of T.B. and Respiratory Diseases as well as their contribution to the specialty.",
          subparagraph:
            "This was instituted in 1972 as “Warner Chest Oration” with the purpose promoting scientific research in the field of Chest Diseases, and in particular in field of allergy.",
          content:
            "The Warner Hindustan Ltd. through the Asthma, Bronchitis & Cancer Lung Foundation of India, funded annually an oration on any aspect of Chest Diseases, to be delivered at the time of the annual conference.<br>On the special request of the donors, the 151 oration was delivered in 1972 by Dr. Vishwanathan, who had perhaps the longest experience in the field of chest diseases. The name of the oration was changed to Parke-Davis / NCCP (I) Chest Oration since 1992, on the request of the organization since Warner Hindustan Company merged in Parke-Davis Company. The award was finally discontinued after 1995. The list of orators is given below.",
        },
        {
          heading: "Raman Viswanathan Memorial Lecture",
          subparagraph:
            "Raman Viswanathan Memorial Lecture was started in 1989, to honour and perpetuate the memory of Late Col. Raman Viswanathan, founder Director, V.P. Chest Institute, a great scientist...",
        },
        {
          heading: "NCCP(I) Cipla Chest Oration",
          subparagraph:
            "NCCP (I) Cipla Chest Oration was created with the objective of arranging oration every year by an eminent scientist working in the field of Pulmonology, preferably on Bronchial Asthma...",
        },
        {
          heading: "NCCP(I) German Remedies Chest Oration",
          subparagraph:
            "This awards was created in 2002 by the financial support of MIS German Remedies. The awards was given every year to an eminent scientist working in the field of Pulmonology. Following...",
        },
        {
          heading: "NCCP(I) Lupin Chest Oration",
          subparagraph:
            "The award was created in 2005 by financial support of M/s Lupin India Ltd. The award is given every year to eminent Pulmonologist. Following persons have received the award:",
        },
        {
          heading: "Young Scientist Award of NCCP(I)",
          subparagraph:
            "To encourage research in young scientists, a cash prize of Rs. 1000/- will be given to the best paper presented by him in the annual Conference of the College. He should be the first...",
        },
      ],
    };
  },
  methods: {
    popupfunction() {
      this.showpopup = !this.showpopup;
    },
    async toggleFuction(id) {
      await this.$router.push({ name: "Award_section", params: { id: id } });
      this.$router.go(0);
      // this.$router.push(`/awards/${id}`);
    },

    toggleMobileMenu() {
      const navbar = document.querySelector("#navbar");
      navbar.classList.toggle("navbar-mobile");
    },
  },

  computed: {
    isActive() {
      return (path) => {
        return this.$route.path === path;
      };
    },
  },
};
</script>

<style scoped>
.navbar .active {
  color: #0d6efd; /* Define your active link styles */
}
a {
  color: #0d6efd;
  text-decoration: none;
}

.icon-fix {
  position: fixed;
  top: 45%;
  left: 0px;
}
.round {
  margin: 0px 0px;
  padding: 10px;
  border-radius: 50%;
}

.bi-facebook {
  color: #0d6efd;
}

.bi-instagram {
  color: red;
}

.bi-twitter-x {
  color: gray;
}

.bi-linkedin {
  color: rgb(11, 45, 196);
}

/*--------------------------------------------------------------
    # Back to top button
    --------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #0d6efd;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #00c28e;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
    # Disable AOS delay on mobile
    --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
    # Header
    --------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  top: 15px;
}

#header .header-container {
  background: white;
  border-radius: 0px;
  height: 60px;
  margin-top: -15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#header .logo {
  border-radius: 10px;
  height: 60px;
  width: 60px;
  padding: 3px;
  display: flex;
  gap: 40px;
  align-items: baseline;
}

#header .logo h1 {
  font-size: 26px;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  position: relative;
  top: 17px;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}

#header .logo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin-left: 21px;
}

@media (max-width: 992px) {
  #header {
    top: 15px;
  }

  #header.header-scrolled,
  #header .header-container {
    height: 50px;
  }

  #header .logo {
    height: 45px;
    width: 45px;
  }

  #header .logo h1 {
    font-size: 24px;
  }

  #header .logo{
    position: relative;
    top: -10px;
  }
  #header .logo img{
    margin-left: 40px;
  }
}

/*--------------------------------------------------------------
    # Navigation Menu
    --------------------------------------------------------------*/
/**
    * Desktop Navigation 
    */
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  color: #36343a;
  transition: 0.3s;
  font-size: 13px;
  font-weight: 700;

  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #0d6efd;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #0d6efd;
  padding: 10px 25px;
  margin-left: 30px;
  margin-right: 15px;
  border-radius: 50px;
  color: #fff;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  cursor: pointer;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  text-transform: none;
  font-weight: 500;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #0d6efd;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
    * Mobile Navigation 
    */
.mobile-nav-toggle {
  color: black;
  font-size: 28px;
  cursor: pointer;
  display: none;
  transition: 0.5s;
  padding-left: 10px;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(29, 28, 31, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #36343a;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #0d6efd;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #0d6efd;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

.pop-up-contact {
  display: grid;
  place-content: center;
  place-items: center;
  width: 100%;
  height: 100vh;

  background: rgba(0, 0, 0, 0.8);
}
.popup-form {
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  padding: 50px;
}

.popup-form i {
  cursor: pointer;
  position: absolute;
  right: 26%;
  margin-top: -30px;
  font-size: 20px;
  color: #0d6efd;
}
.formsubmit {
  padding: 30px;
}

.form-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.form-input input {
  width: 300px;
  border: none;
  border-bottom: 1px solid #0d6efd;
  background: transparent;
}

::placeholder {
  color: #fff;
}

.input-message {
  width: 100%;
  background: transparent;
  border: none;
  border-bottom: 1px solid #0d6efd;
}
.submit {
  text-align: center;
}
.submit input {
  padding: 3px 10px;
  width: 50%;
  background: transparent;
  color: #fff;
  border: 3px solid #0d6efd;
}

.submit input:hover {
  padding: 3px 10px;
  width: 50%;
  background: #0d6efd;
  color: #fff;
  border: 3px solid #0d6efd;
  transition: 0.5s;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    min-width: 100%;
    padding: 0px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    min-width: 100%;
    padding: 0px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    min-width: 100%;
    padding: 0px;
  }
}

@media (min-width: 576px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    min-width: 100%;
    padding: 0px;
  }
}

@media screen and (max-width: 576px){
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding: 0;

}
}

.navbar-mobile {
  display: block;
}

.dropdown-active ul {
  display: block;
}

@media (max-width: 768px) {
  #navbar ul {
    display: none;
  }
  .navbar-mobile {
    display: block;
  }
  .dropdown ul {
    display: none;
  }
  .dropdown-active ul {
    display: block;
  }
}
  
</style>
