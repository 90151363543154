<template>
  <div id="app">
    <HeaderComponent v-if="showHeaderFooter" /><br>
    <router-view></router-view><br>
    <FooterComponent v-if="showHeaderFooter" />
  </div>
</template>

<script>
import 'lineicons/dist/lineicons.css';

import AOS from 'aos';
import 'aos/dist/aos.css';
// ..
AOS.init();
import HeaderComponent from "./components/HeaderComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";

export default {
  components: {
    HeaderComponent,
    FooterComponent,
  },
  computed: {
    showHeaderFooter() {
      return this.$route.meta.showHeaderFooter !== false;
    }
  }
};
</script>

<style>
* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
