<template>
     <BannerOtherPages title="Social Media Platform" />
     <div class="container mt-5">
     <div class="page-title mt-4" style="width: 100%; "><h2>Connect, share, and discover with your community</h2></div>
     <div class="container-fluid">
      <div class="row">
        <!-- Sidebar -->
        <aside class="col-md-3 bg-light py-5 d-none d-md-block shadow">
          <ul class="nav flex-column text-center">
            <li class="nav-item mb-3">
              <a href="#" class="nav-link">
                <i class="bi bi-house-fill fs-4"></i>
                <span class="d-block mt-2">Home</span>
              </a>
            </li>
            <li class="nav-item mb-3">
              <a href="#" class="nav-link">
                <i class="bi bi-person-fill fs-4"></i>
                <span class="d-block mt-2">Profile</span>
              </a>
            </li>
            <li class="nav-item mb-3">
              <a href="#" class="nav-link">
                <i class="bi bi-bell-fill fs-4"></i>
                <span class="d-block mt-2">Notifications</span>
              </a>
            </li>
            <li class="nav-item mb-3">
              <a href="#" class="nav-link">
                <i class="bi bi-gear-fill fs-4"></i>
                <span class="d-block mt-2">Settings</span>
              </a>
            </li>
          </ul>
        </aside>

        <!-- Main Content -->
        <main class="col-md-9 py-5">
          <!-- Create Post Section -->
          <div class="card mb-4 shadow-sm">
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <img
                  src="https://via.placeholder.com/50"
                  class="rounded-circle me-3"
                  alt="User Avatar"
                />
                <textarea
                  v-model="newPost"
                  class="form-control"
                  placeholder="What's on your mind?"
                  rows="3"
                ></textarea>
              </div>
              <button
                class="btn btn-primary w-100"
                @click="addPost"
                :disabled="!newPost.trim()"
              >
                Post
              </button>
            </div>
          </div>

          <!-- Feed Section -->
          <div v-for="post in posts" :key="post.id" class="card mb-4 shadow-sm">
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <img
                  :src="post.avatar"
                  class="rounded-circle me-3"
                  alt="Avatar"
                  width="50"
                  height="50"
                />
                <div>
                  <h5 class="mb-0">{{ post.user }}</h5>
                  <small class="text-muted">{{ post.time }}</small>
                </div>
              </div>
              <p>{{ post.content }}</p>
              <div class="d-flex justify-content-between">
                <button class="btn btn-outline-primary btn-sm">
                  <i class="bi bi-hand-thumbs-up"></i> Like
                </button>
                <button class="btn btn-outline-secondary btn-sm">
                  <i class="bi bi-chat-dots"></i> Comment
                </button>
                <button class="btn btn-outline-success btn-sm">
                  <i class="bi bi-share"></i> Share
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
     </div>
</template>
<script>
import BannerOtherPages from '@/components/BannerOtherPages.vue';

export default {
    components: {
        BannerOtherPages
    },
    data() {
    return {
      newPost: "",
      posts: [
        {
          id: 1,
          user: "John Doe",
          content: "Enjoying a beautiful day at the park!",
          time: "2 hours ago",
          avatar: "https://via.placeholder.com/50",
        },
        {
          id: 2,
          user: "Jane Smith",
          content: "Just finished my morning workout. Feeling great!",
          time: "5 hours ago",
          avatar: "https://via.placeholder.com/50",
        },
        {
          id: 3,
          user: "Alex Johnson",
          content: "Anyone up for a coffee this afternoon?",
          time: "1 day ago",
          avatar: "https://via.placeholder.com/50",
        },
      ],
    };
  },
  methods: {
    addPost() {
      this.posts.unshift({
        id: this.posts.length + 1,
        user: "You",
        content: this.newPost,
        time: "Just now",
        avatar: "https://via.placeholder.com/50",
      });
      this.newPost = "";
    },
  },
}

</script>

<style scoped>

.banner_content{
background:url(https://cdn.pixabay.com/photo/2018/05/16/18/03/social-media-3406607_1280.jpg);
position: relative;
height: 450px;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: top;
display: grid;
place-items: center;
place-content: center;
}

</style>