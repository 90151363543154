<template>
    <BannerOtherPages title="Radiology On Mobile" />
    <div>
        <div class="container-fluid cont-wraper">
        <div class="container">
            <div class="site-contant">
                <div class="content">
                <div class="page-title"><h2>SOLUTION FOR YOUR HEALTH IT (HIT), RADIOLOGY OR TELERADIOLOGY</h2></div>
                    <p>
                        <strong>Radiology &amp; Teleradiology Solutions</strong>
                    </p>
                    <p>
                        <strong>PACS (Picture Archieving and Communication System) or Medical Imaging Technology</strong>
                    </p>
                    <p>
                        <strong>Radiology Workflow Solutions (PACS &amp; RIS workflow for Radiology)</strong>
                    </p>
                    <p>
                        <strong>Imaging Solutions for Radiologists (Workstations &amp; Medical Grade Monitor Systems)</strong>
                    </p>
                    <p>
                        <strong>Radiology &amp; Teleradiology Sales, Support and Services</strong>
                    </p>
                    <p>
                        <strong>Services for Radiologist (Radiology and Teleradiology)</strong>
                    </p>
                    <p>
                        <strong>Services for Teleradiologist (RIS-PACS Service, Support and Installation)</strong>
                    </p>
                    <p>
                        <strong>Services for Imaging Centers (Teleradiology, Imaging and Reporting Services for Imaging Centers)</strong>
                    </p>
                    <p>
                        <strong>IT Support Services</strong>
                    </p>
                    <p>
                        <strong>Custom Integration Services (Teleradiology Application and PACS System Modality Integration)</strong>
                    </p>
                    <p>
                        <strong>Software, Hardware, Web Applications and PACS Support Services</strong>
                    </p>

                </div>
            </div>
        </div>
    </div>
    </div>
  </template>
  
  
  <script>
  
  import BannerOtherPages from '@/components/BannerOtherPages.vue';
  
  export default {
      components: {
          BannerOtherPages
      }
  }
  
  </script>
  
  <style scoped>
  
  .banner_content{
  background:url(../assets/img/carrer-img.jpg);
  position: relative;
  height: 450px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  display: grid;
  place-items: center;
  place-content: center;
  }
  
  </style>